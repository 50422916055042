
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { getUserName, useFilter, usePagination, formatCPF, isCPF } from "@/libs/utils";
import { Employee } from "@/store/employee/types";

const VEmployeeList = defineComponent({
  name: "VEmployeeList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de usuários | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const employees = computed(() => store.state.employee.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(employee: Employee) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "employee-edit", params: { _id: String(employee.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "employee-edit", params: { _id: String(employee.id) } },
        },
        {
          label: "Atualizar senha",
          icon: "fas fa-lock",
          to: { name: "employee-edit-password", params: { _id: String(employee.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "employee-delete", params: { _id: String(employee.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Usuários",
        icon: "fas fa-user-friends mr-1",
        to: { name: "employee-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "employee",
      setPage: "setEmployeePaginationPage",
      updateList: getEmployees,
    });

    async function getEmployees() {
      loading.list = true;
      await store.dispatch("getEmployees", { config: { useCompany: true } });
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "employee-filter", query: route.query });
    else getEmployees();

    return {
      ...{ employees, pagination, loading, breadcrumb, hasFilters },
      ...{ getUserName, getDropdownOptions, handleUpdateCurrentPage, getEmployees },
      ...{ formatCPF, isCPF },
    };
  },
});

export default VEmployeeList;
